// Left from @marckong here: slightly modified from this PR - https://github.com/Kong/insomnia-website/pull/41
import type { PropsWithChildren } from 'react';
import { useEffect, useRef, useState } from 'react';

import TrailLines, { type TrailsLineHandle } from '~/components/authorize/trail-lines';

type Size = {
  width: number;
  height: number;
};

export function TrailLinesContainer({ children }: PropsWithChildren) {
  const startTailRef = useRef<TrailsLineHandle>(null);
  const endTailRef = useRef<TrailsLineHandle>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<Size | undefined>();

  useEffect(() => {
    startTailRef.current?.toggle(true);
    endTailRef.current?.toggle(true);
  }, [dimensions]);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    startTailRef.current?.toggle(false);
    endTailRef.current?.toggle(false);

    const width = containerRef.current?.clientWidth;
    const height = containerRef.current?.clientHeight;

    if (!width || !height) {
      return;
    }

    if (width < 500) {
      return;
    }

    const matrix: Size = { width: width / 2, height: height };

    setDimensions(matrix);
  };

  return (
    <div className="relative z-0 mx-auto flex w-full items-center justify-center overflow-hidden" ref={containerRef}>
      <div className="flex items-center">
        {dimensions && <TrailLines id="start" ref={startTailRef} width={dimensions.width} height={dimensions.height} />}
      </div>
      {children}
      <div className="flex items-center">
        {dimensions && (
          <TrailLines id="end" ref={endTailRef} width={dimensions.width} height={dimensions.height} reverse />
        )}
      </div>
    </div>
  );
}
